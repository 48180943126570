import Container from 'components/Container/Container';
import Hero from 'components/Hero/Hero';
import Layout from 'components/Layout/Layout';
import TrialCard from 'components/TrialCard/TrialCard';
import styles from 'pages/index.module.scss';
import AdminLoginModal from 'components/AdminLoginModal/AdminLoginModal';
import { getIconComponent } from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

const HomepageTemplate = ({ location }) => {
  const {
    allTrial: { edges },
  } = useStaticQuery(graphql`
    query TrialQuery {
      allTrial {
        edges {
          node {
            nct_id
            brief_title
            about_trial
            overall_recruitment_status
            modified_at
            primary_benefit {
              icon_name
              id
              name
            }
            primary_intervention {
              icon_name
              id
              name
            }
            curation_status
          }
        }
      }
    }
  `);
  const aboutLists = [
    {
      id: 1,
      icon: 'checkBadge',
      text: (
        <Content>
          <strong>Many curated trials</strong> by experts.
        </Content>
      ),
    },
    {
      id: 2,
      icon: 'alarmBell',
      text: (
        <Content>
          <strong>Get notified instantly</strong> about new trials that fit you,
          without registration.
        </Content>
      ),
    },
    {
      id: 3,
      icon: 'mailFast',
      text: (
        <Content>
          <strong>Seamless contact</strong> to trial locations through a secure
          form that protects your privacy.
        </Content>
      ),
    },
  ];

  const isModalOpen = location.state ? location.state.isLoginModalOpen : false;

  return (
    <>
      <Layout
        title="Find spinal cord injury trials"
        description="Start your search now for fitting trials to your spinal cord injury and learn more about SCI Trials Finder."
        hideSearch
      >
        <Hero />
        <Container size="md">
          <Heading theme="primary" size="h2" className={styles.cardHeading}>
            Latest curated trials
          </Heading>
          <div className={styles.trialsContainer}>
            {edges.map(
              ({
                node: {
                  nct_id: nctId,
                  brief_title: briefTitle,
                  about_trial: aboutThisTrial,
                  modified_at: modifiedAt,
                  primary_intervention: primaryIntervention,
                  primary_benefit: primaryBenefit,
                  curation_status: curationStatus,
                  overall_recruitment_status: recruitingStatus,
                },
              }) => (
                <TrialCard
                  key={nctId}
                  to={`/trials/${nctId}`}
                  title={briefTitle}
                  content={aboutThisTrial}
                  recruitingStatus={recruitingStatus}
                  modifiedDate={modifiedAt}
                  primaryBenefit={primaryBenefit}
                  primaryIntervention={primaryIntervention}
                  curation={curationStatus}
                />
              ),
            )}
          </div>
          <div className={styles.showAllButton}>
            <Button
              as={GatsbyLink}
              isFullWidth
              to="/trials"
              size="default"
              theme="secondary"
            >
              Show all curated trials
            </Button>
          </div>
        </Container>
        <br />
        <div>{/* sponsors */}</div>
        <br />
        <Container size="xl" className={styles.aboutComponent}>
          <div className={styles.imageContainer} />

          <div className={styles.infoTextContainer}>
            <Heading className={styles.heading} size="h2" theme="primary">
              Keeping Promises: Translating Basic Research Into New Spinal Cord
              Injury Therapies
            </Heading>
            <Content size="copy100" className={styles.aboutContent}>
              Centuries of medical wisdom – namely that spinal cord injury (SCI)
              treatment was limited to caretaking until the patients inevitably
              succumbed to complications – has given way to tremendous medical
              and research advancements.
            </Content>
            <div className={styles.lists}>
              {aboutLists.map(list => {
                return (
                  <div key={list.id} className={styles.listWrapper}>
                    <div className={styles.iconWrapper}>
                      <IconWrapper
                        Icon={getIconComponent(list.icon)}
                        className={styles.icon}
                      />
                    </div>
                    <div className={styles.text}>{list.text}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.aboutButton}>
              <Button
                isFullWidth
                size="default"
                theme="secondary"
                as={GatsbyLink}
                to="/about"
              >
                About SCI Trials Finder
              </Button>
            </div>
          </div>
          <AdminLoginModal hidden isLoginModalOpen={isModalOpen} />
        </Container>
        <br />
      </Layout>
    </>
  );
};

HomepageTemplate.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

HomepageTemplate.defaultProps = {
  location: undefined,
};

export default HomepageTemplate;
