import styles from 'components/Hero/Hero.module.scss';
import Container from 'components/Container/Container';
import SearchBarWrapper from 'components/SearchBar/SearchBarWrapper';
import React from 'react';

import Heading from '@moonshineragency/ui/src/components/Heading/Heading';

const Hero = () => {
  return (
    <>
      <div className={styles.heroImageWrapper}>
        <Container>
          <Heading theme="primary" size="h1" className={styles.heroText}>
            Find a Clinical Trial that fits your Spinal Cord Injury
          </Heading>
        </Container>
        <div className={styles.search}>
          <SearchBarWrapper variant="expanded" initialState />
        </div>
      </div>
      {/* <div className={styles.heroImageMobileWrapper}>
        <Container>
          <Heading theme="primary" className={styles.heroMobileText}>
            {label}
          </Heading>
          <div className={styles.search}>
            <SearchBarWrapper variant="expanded" />
          </div>
        </Container>
      </div> */}
    </>
  );
};

export default Hero;
